<template>
  <div>
    <van-steps :active="1">
      <van-step>加入购物车</van-step>
      <van-step>提交订单</van-step>
      <van-step>订单付款</van-step>
      <van-step>卖家发货</van-step>
    </van-steps>
    <van-cell-group>
      <van-cell @click="$router.push({name: 'flow'})" is-link title="订单商品" value="返回修改"></van-cell>
      <van-card :key="index" :num="item.num" :price="item.price_format" :thumb="item.titlepic" :title="item.title" currency v-for="(item, index) in cart.list"></van-card>
    </van-cell-group>
    <van-cell-group title="收货人信息">
      <van-field :required="true" colon label="收货人" left-icon="friends" name="truename" placeholder="收货人姓名" type="text" v-model="order.truename"></van-field>
      <van-field :required="true" colon label="手机" left-icon="phone" name="phone" placeholder="有效手机号码" type="text" v-model="order.phone"></van-field>
      <van-field autosize colon label="详细地址" left-icon="wap-home" name="address" placeholder="请具体到街道小区楼层" type="textarea" v-model="order.address"></van-field>
      <van-field colon label="邮箱" left-icon="envelop-o" name="email" placeholder="[选填]例：123456789@qq.com" type="email" v-model="order.email"></van-field>
    </van-cell-group>
    <van-collapse class="mt-15" v-model="activeNames">
      <van-collapse-item name="1" title>
        <template #title>
          <div class="tc h-10 bb danger">选择其他地址</div>
        </template>
        <van-cell @click="AddressModalVisible=true" icon="add" is-link title="创建新地址"></van-cell>
        <template v-for="(item, index) in addressList">
          <van-cell :key="index" :label="item.truename+','+item.phone+','+item.address" :title="item.addressname" @click="SelectAddress(item)" center icon="location" is-link v-if="item.addressid!=addressid"></van-cell>
        </template>
      </van-collapse-item>
    </van-collapse>
    <van-radio-group v-model="order.psid">
      <van-cell-group title="选择配送方式">
        <van-cell :key="index" :title="ps.pname" :value="ps.price>0 ? ps.price_format : '无费用'" v-for="(ps, index) in shippingList">
          <template #icon>
            <van-radio :name="ps.pid*1" @click="getCartTotal" class="mr-10"></van-radio>
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <van-collapse class="mt-10" v-model="fpActiveNames">
      <van-collapse-item :label="fplabel" :value="order.fp?'开':''" center name="1" title="发票">
        <van-cell-group :border="false" title="发票设置">
          <van-cell>
            <van-checkbox @change="getCartTotal" shape="square" v-model="order.fp">开发票[税点{{shoppr && shoppr['fpnum']}}]</van-checkbox>
          </van-cell>
          <van-field @input="getFpTt" label="发票抬头" name="fpqiye" placeholder="企业名称" v-model="fpqiye"></van-field>
          <van-field @input="getFpTt" label="税号" name="fpcode" placeholder="企业社会信用代码" v-model="fpcode"></van-field>
        </van-cell-group>
        <van-radio-group v-model="order.fpname">
          <van-cell-group title="发票内容">
            <van-cell :key="fpn" v-for="fpn in fpnamer">
              <van-radio :name="fpn">{{fpn}}</van-radio>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </van-collapse-item>
    </van-collapse>
    <van-cell-group class="mb-10" title="使用优惠码(没有请留空)">
      <van-field colon label="优惠码" placeholder="填入有效优惠码" v-model="order.precode">
        <template #button>
          <van-button @click="getCartTotal" native-type="button" size="small">确定</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-cell-group v-if="total">
      <van-cell :value="cart.totalmoney_format" title="商品总价"></van-cell>
      <van-cell :value="'+ '+total.pstotal_format" title="快递费用"></van-cell>
      <van-cell :value="'+ '+total.fptotal_format" title="发票税额"></van-cell>
      <van-cell :value="'- '+total.pretotal_format" title="优惠券"></van-cell>
      <van-cell :value="'= '+total.totalamount_format" title="订单合计"></van-cell>
    </van-cell-group>
    <van-cell-group>
      <van-field colon label="订单留言" placeholder="选填，可告诉店长您的特殊要求" type="textarea" v-model="order.bz"></van-field>
    </van-cell-group>
    <div class="subutton">
      <van-button @click="doSubmitOrder" block native-type="button" type="primary">完成订单</van-button>
    </div>
    <van-dialog @confirm="doSaveAddress" show-cancel-button title="新增地址" v-model="AddressModalVisible">
      <van-cell-group>
        <van-field label="地址名称" placeholder="起个名字 如：公司地址" v-model="newAddress.addressname"></van-field>
        <van-field label="收货人" placeholder="请填写收货人" v-model="newAddress.truename"></van-field>
        <van-field label="手机号" placeholder="请填写有效手机号" v-model="newAddress.phone"></van-field>
        <van-field autosize label="详细地址" placeholder="请具体到省市区街道门牌" type="textarea" v-model="newAddress.address"></van-field>
        <van-button @click="doSaveAddress"></van-button>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { hasHistory } from "@/libs/tools";
import {
  Steps,
  Step,
  Button,
  RadioGroup,
  Radio,
  ActionSheet,
  CellGroup,
  Cell,
  Field,
  Collapse,
  CollapseItem,
  checkbox,
  Toast,
  Card,
} from "vant";
Vue.use(Steps)
  .use(Step)
  .use(Button)
  .use(ActionSheet)
  .use(CellGroup)
  .use(Cell)
  .use(RadioGroup)
  .use(Radio)
  .use(Field)
  .use(Collapse)
  .use(CollapseItem)
  .use(checkbox)
  .use(Toast)
  .use(Card);

export default {
  name: "checkout",
  data() {
    return {
      loading: false,
      isReady: false,
      AddressModalVisible: false,
      newAddress: {},
      addressid: 0,
      activeNames: [],
      fpActiveNames: [],
      fpqiye: "",
      fpcode: "",
      shoppr: null,
      order: {},
      user: {},
      cart: {},
      total: {},
      addressList: [],
      shippingList: [],
      fpnamer: [],
    };
  },
  computed: {
    fplabel() {
      if (!this.fpActiveNames.length && this.order.fp) {
        if (!this.fpqiye || !this.fpcode) {
          return "请完善信息";
        } else {
          return `[${this.order.fpname}],${this.fpqiye},${this.fpcode}`;
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    getData() {
      if (this.loading) return;
      this.loading = true;
      this.$http.get("e/ShopSys/order/").then((res) => {
        this.loading = false;
        if (res.code === 9) return;
        if (res.code === 0) {
          this.isReady = true;
          this.setData(res.data);
        } else {
          if (hasHistory()) {
            this.$router.go(-1);
          } else {
            this.$router.push({ name: "index" });
          }
        }
      });
    },
    doSubmitOrder() {
      this.order.payfsid = 8;
      this.$http
        .post("e/ShopSys/?do=AddDd", this.order)
        .then((res) => {
          if (res.code === 0) {
            this.$router.push({ name: "order", params: { id: res.data } });
          }
        })
        .catch((error) => {
          Dialog.alert({ message: error });
        });
    },
    getFpTt() {
      this.order.fptt = this.fpqiye + "," + this.fpcode;
    },
    getCartTotal() {
      var data = this.order;
      data.fp = data.fp ? 1 : 0;
      this.$http
        .post("e/ShopSys/?do=CartTotal", data)
        .then((res) => {
          if (res.code === 0) {
            this.total = res.data;
          } else {
            this.$alert(res.msg);
            this.order.precode = "";
          }
        })
        .catch((error) => {
          this.$alert(error);
        });
    },
    SelectAddress(addr) {
      this.order.truename = addr.truename;
      this.order.phone = addr.phone;
      this.order.email = addr.email;
      this.order.address = addr.address;
      Toast("使用地址成功");
      this.activeNames = [];
    },
    getAddressList() {
      this.$http.post("e/ShopSys/?do=GetAddressList").then((res) => {
        if (res.code === 0) this.addressList = res.data;
      });
    },
    doSaveAddress() {
      this.$http
        .post("e/ShopSys/?do=AddAddress", this.newAddress)
        .then((res) => {
          if (res.code === 0) {
            this.AddressModalVisible = false;
            this.getAddressList();
          }
        });
    },
  },
  activated() {
    this.getData();
    this.$store.commit("setPageTitle", "确认订单信息");
  },
};
</script>

<style scoped>
.subutton {
  padding: 15px 15px 100px;
}
</style>